<template>
  <div class="d-flex justify-content-start">
    <div>
      {{ params.value }}
      <b-badge
        v-if="unseen_chat_count !== 0"
        pill
        variant="danger"
      >
        {{ unseen_chat_count }}
      </b-badge>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CellRendererLink',
  computed: {
    url() {
      return `/help-desks/issues/${this.params.data._id}`
    },
    unseen_chat_count() {
      const data = this.params.data
      let chat_seen_tracker_count = 0
      if (!this.$isEmpty(data.chat_seen_tracker)) {
        chat_seen_tracker_count = data.chat_seen_tracker[this.$store.state.auth.ActiveUser.email] || 0
      }

      return data.total_chats - chat_seen_tracker_count
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/variables.scss";

a:hover {
  color: $primary  !important;
}
</style>