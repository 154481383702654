<template>
  <b-dropdown
    split
    variant="outline-dark"
    :text="
      (
        pagination.totalRows > 0
          ? (pagination.currentPage * pagination.perPage - (pagination.perPage - 1))
          : 0
      )
        + ' - ' +
        (pagination.totalRows -
          pagination.currentPage * pagination.perPage >
          0
          ? pagination.currentPage * pagination.perPage
          : pagination.totalRows) +
        ' of ' +
        pagination.totalRows
    "
    right
    style="height: 40px"
  >
    <!-- <b-button class="btn-drop" type="line" variant="primary" icon-pack="feather" icon="icon-chevron-down"></b-button> -->

    <b-dropdown-item @click="$emit('per-page-changed', 10)">
      <span>10</span>
    </b-dropdown-item>
    <b-dropdown-item @click="$emit('per-page-changed', 50)">
      <span>50</span>
    </b-dropdown-item>
    <b-dropdown-item @click="$emit('per-page-changed', 100)">
      <span>100</span>
    </b-dropdown-item>
    <b-dropdown-item @click="$emit('per-page-changed', 200)">
      <span>200</span>
    </b-dropdown-item>
    <b-dropdown-item @click="$emit('per-page-changed', 500)">
      <span>500</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    pagination: {
      type: Object,
      default: () => {
        return {
          currentPage: 1,
          perPage: 10,
          totalRows: 1,
        }
      },
    },
  },
}
</script>

<style>

</style>