var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-dropdown',{staticStyle:{"height":"40px"},attrs:{"split":"","variant":"outline-dark","text":(
      _vm.pagination.totalRows > 0
        ? (_vm.pagination.currentPage * _vm.pagination.perPage - (_vm.pagination.perPage - 1))
        : 0
    )
      + ' - ' +
      (_vm.pagination.totalRows -
        _vm.pagination.currentPage * _vm.pagination.perPage >
        0
        ? _vm.pagination.currentPage * _vm.pagination.perPage
        : _vm.pagination.totalRows) +
      ' of ' +
      _vm.pagination.totalRows,"right":""}},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.$emit('per-page-changed', 10)}}},[_c('span',[_vm._v("10")])]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.$emit('per-page-changed', 50)}}},[_c('span',[_vm._v("50")])]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.$emit('per-page-changed', 100)}}},[_c('span',[_vm._v("100")])]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.$emit('per-page-changed', 200)}}},[_c('span',[_vm._v("200")])]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.$emit('per-page-changed', 500)}}},[_c('span',[_vm._v("500")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }